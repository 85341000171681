@import "../../../scss/index.scss";

.register-form {
  margin-top: 100px;

  h1 {
    color: black;
    text-align: center;
    font-size: 20px;
    padding-bottom: 58px;
    margin-bottom: 0%;
  }

  .select {
    width: 100% !important;
    border-bottom: 2px solid #c2c2c2 !important;
  }

  .input {
    width: 100% !important;
    border: 0 !important;
    border-bottom: 2px solid #c2c2c2 !important;
  }

  .register {
    color: #ffffff !important;
    text-align: center;
    width: 100%;
  }

  &__options {
    margin-top: 10px;
    color: $font-light;
    text-align: center;
    font-size: 16px;
    text-transform: none;
  }
}
