.main-header {
    display: flex;
    justify-content: space-between;
    background-color: rgba(#ffffff, 1);
    height: 60px;
    width: 100vw;
    top: 0px;
    left: 0px;
    position: fixed;
    z-index: 100;
    box-shadow: -5px 1px 10px #666; 
   
    .img-logo-title {
      height: 55px;
    }
  
  
    .li_options {
      display: inline;
      margin-right: 15px;
      padding-top: -10px;
    }
   
    .avatar-header {
      margin: 8px !important;
    }
   
    .icon-header {
      font-size: 20px !important;
    }

    .avatar-item {
        margin-right: 24px;
      }
      
      [class*='-col-rtl'] .avatar-item {
        margin-right: 0;
        margin-left: 24px;
      }
      
  }

  .ant-dropdown {
    top: 35px !important;
    
  }

  .form-edit-perfil {

    &__form {
        .MuiFormControl-root {
            width: 100%;
        }

        &-input {

            width: 100% !important;
            border: 0 !important;
            border-bottom: 2px solid #c2c2c2 !important;

        }
        &-button {
            color: #ffffff !important;
            text-align: center;
            //margin-top: 20px;
            width: 100%;
        }
    }
}

.form-dropdown-perfil {

  &__form {
      .MuiFormControl-root {
          width: 100%;
          
      }

      &-input {

          width: 100% !important;
          border: 0 !important;
          border-bottom: 2px solid #c2c2c2 !important;

      }
      &-button {
          color: #ffffff !important;
          text-align: center;
          //margin-top: 20px;
          width: 100%;
      }
  }
  
  .ant-list-item {
    padding: 8px !important;
  }

}

.dropdown-name {
  float:right;
}

.dropdown-email {
  float:right;
}

.avatar-dropdown {
  float:left;
  width: 70px !important;
  height: 70px !important;
}

.ant-list-item {
  padding: 8px !important;
}

.chooseFileButton {
  border-radius: 0px !important;
  background-color: #6A3E98 !important;
}

.deleteImage {
  font-size: 15px !important;
  background-color: #66C6F1 !important;
}