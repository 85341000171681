@import "../../scss/index.scss";

.auth {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  &__box {
    position: relative;
    background-color: #F7F6F5;
    width: 100%;
    z-index: 1;
    padding: 30px 20px;
    border-radius: 10px;

    &-logo {
      text-align: center;
      img {
        width: 150px;
      }
    }
  }

  h1, h2  {
    color: black;
    padding: 3rem 0;
    text-align: center;
   
  
    text-transform: none;
  
    font-family: 'Staatliches', Arial, Helvetica, sans-serif;
    letter-spacing: 1px;
  }
}