@import "../../../scss/index.scss";

.auth-options {
  margin-top: 0px;

  h2 {
    font-size: 17px;
    color: #008AF5 ;
   /*
    font-weight: bold;
  */
    text-align: center;
    margin-bottom: 0%;
  }

  .box {
    position: relative;
    top:100px; left: 100px;
    background-color: #F7F6F5;
    width: 100%;
    z-index: 1;
    padding: 30px 20px;
    border-radius: 10px;

    &-logo {
      margin-bottom: 17%;
      text-align: center;
      img {
        width: 150px;
      }
    }
  }

  .register,
  .login {
    color: #ffffff !important;
    text-align: center;
    width: 100%;
    
  }

  .ui.button.register {
    /*background-color: $secundary-color;*/
    color: white;
  }
  .ui.button.login {
    /*background-color: $primary-color;*/
    color: white;
  }
}