$primary-color: #6A3E98;
$secundary-color: #66C6F1;
/*
export const colorPrm = "#6A3E98";
export const colorSec = "#66C6F1";
$primary-color: #1db954;
$secundary-color: #00b4d8;

*/

$primary-color-hover: #1ed75f;

$font-light:  #fff;
$font-dark: #008AF5;

$border-light-dark: #9e9e9e;
$border-grey-light: #3b3b3b;

$background-primary: #F8961E;
$background-secondary: #EF233C;
$background-third: #FF5400;
$background-light: #fff;
$background-grey-light: #3b3b3b;
$background-grey: #2d2d2d;
$background-greu-medium: #1c1c1c;
$background-grey-dark: #101010;
$background-sky-blue: #90E0EF;
$background-grey: #E6E6E6;

$background-grey-dark-light: #141414;
$background-dark: #000;

$background-grey-light-hover: #494949;
$background-grey-hover: #3c3c3c;

$danger: #f00;