html, body {
    margin: 0px;
    padding: 0px;

    /*overflow: hidden;*/
}
@media only screen and (max-width: 769px) {
    #root {
        display: none;
    }
    #mobile {
      display: block;
    }

}

.row::after {
    content: "";
    clear: both;
    display: table;
  }

  .ant-row {
    height: 100% !important;
  }

  .ant-layout {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  /* div del contenido Derecho */
  .side-content-left {
    height: 100%;
    /* Arriba | Derecha | Abajo | Izquierda */
    padding: 0 20px 0 20px;
  }

  /* Imagen de Fondo del Content Derecho */
  .side-back-img-left {
    //position: fixed;
    height: 100% !important;
    background-size: cover;
    /* Arriba | Derecha | Abajo | Izquierda */
    padding: 60px 0 30px 0;

  }

  /* div del contenido izquierdo App */
  .side-content-app {
    height: 100%;/*90vh;*/
    padding-top: 60px;
    

  }

  /* Div del texto del contenido Derecho */
  .side-text-content {
      margin-top: 5.4rem;
      align-items: center;
      justify-content: center;
      font-family: Poppins, Helvetica, sans-serif;
      /*
      border: solid;
      border-color: blue;*/
  
  }

  .logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;

  }

  .img-logo {
    max-width: 150px !important;
  }

  /* fondo del contenido de app Izquierdo */
  .bg-backgr-app {
    background-position: center;
    background-size: 100% 100%;
    min-height: 100%;
  }

  /* Creditos del contenido derecho */
  .copy-foot {
    color: #ffffff;
    /* Arriba | Derecha | Abajo | Izquierda */
    padding: 0px 20px;
    text-align: right;
    font-family: Poppins, Helvetica, sans-serif;
  }
  
  .footer-right {
    text-align: right;
    margin-right: 15px;
  }
  
  .side-backgr-div {
    display: flex;
    height: 33.33%;
  }
  
  [class*="col-"] {
    float: left;
  }
  
  .aside {
    background-color: #33b5e5;
    padding: 15px;
    color: #ffffff;
    text-align: center;
    font-size: 14px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  
    /* For desktop: */
    .col-1 {
      width: 8.33%;
    }
    .col-2 {
      width: 16.66%;
    }
    .col-3 {
      width: 25%;
    }
    .col-4 {
      width: 33.33%;
    }
    .col-5 {
      width: 41.66%;
    }
    .col-6 {
      width: 50%;
    }
    .col-7 {
      width: 58.33%;
    }
    .col-8 {
      width: 66.66%;
    }
    .col-9 {
      width: 75%;
    }
    .col-10 {
      width: 83.33%;
    }
    .col-11 {
      width: 91.66%;
    }
    .col-12 {
      width: 100%;
    }

    /* Titulo del contenido Derecho */
    .wcome-title {
      color: rgba(#ffffff, 1);
      font-size: 2.5rem;
      font-weight: 500;
      font-weight: bold;
      /*margin-left: 1rem;*/
/*
      border: solid;
      border-color: red;*/
    }

    /* Subtitulo del contenido Derecho*/
    .wcome-subtitle {
      /*font-size: 1.4rem;*/
      font-weight: 400;
      margin: 1.5rem 0 6.5rem 1rem;
      color: rgba(255, 255, 255, 0.8);
      font-style: italic;
      font-size: 1.8rem !important;
/*
      border: solid;
      border-color: black;*/
    }

  

.button-utils {
  left: 10px !important;
}

.ant-layout-footer {
  padding: 0px !important;
}
