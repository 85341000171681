@import "../../../scss/index.scss";

.login-form {
  margin-top: 30px;

  h1 {
    color: black;
    text-align: center;
    font-size: 20px;
    padding-bottom:20px;
    margin-bottom: 42%;
  }

  .input {

    width: 100% !important;
    border: 0 !important;
    box-shadow: blue($color: #000000);
    border-bottom: 2px solid #c2c2c2 !important;
  }
}